<template>
    <div class="content">
        <Form label-position="left" :label-width="80">
            <FormItem label="公告">
                <textarea class="textar" v-model="notice"></textarea>
            </FormItem>
            <FormItem label="">
                <Button @click="addBtn" style="color:#FFF;background:#34B8FF;border-color:#34B8FF">确定</Button>
            </FormItem>
        </Form>
    </div>
</template>

<script>
    import {getNotice,setNotice} from "@/api/index.js";
    export default {
        data(){
            return{
                notice:'',
            }
        },
        created(){
            this.getNotice()
        },
        methods:{
            getNotice(){
                getNotice().then(res=>{
                    this.notice = res.data.notice
                })
            },
            addBtn(){
                setNotice({notice:this.notice}).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
        }
    }
</script>

<style scoped>
    .content{
        width: 100%;
        height: calc(100vh - 104px);
        overflow: auto;
        padding: 20px 40px;
        background: #FFF;
        border-radius: 8px;
        box-shadow: #F4F0E8 0px 1px 2px 0px;
    }
    .textar{
        outline: none;
        width: 300px;
        min-height: 100px;
        max-height: 100px;
        border-color: #DCDEE2;
        border-radius: 10px;
    }
</style>